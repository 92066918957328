import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/assets/utils/auth'

/* Layout */
import Layout from '@/layout/main-layout'
// 白名单，单token被清除后，跳转到登录页面或者指定页面
const whiteList = ['/login']

Vue.use(Router)

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noredirect           // 当设置 noredirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    noCache: true                // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/login',
    meta: { title: '登录', noCache: true },
    component: (resolve) => require(['@/views/login'], resolve),
    hidden: true
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: (resolve) => require(['@/views/redirect'], resolve)
      }
    ]
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  {
    path: '/401',
    component: (resolve) => require(['@/views/401'], resolve),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [{
      path: 'dashboard',
      name: 'Dashboard',
      component: () => import('@/views/dashboard/index'),
      meta: { title: '首页', icon: 'index', affix: true, noCache: true }
    }]
  },
  {
    path: '/profile',
    component: Layout,
    redirect: '/profile/index',
    hidden: true,
    children: [
      {
        path: 'index',
        component: () => import('@/views/profile/index'),
        name: 'Profile',
        meta: { title: '个人中心', icon: 'user', noCache: true }
      }
    ]
  },
  // 系统权限管理
  {
    path: '/perms',
    component: Layout,
    redirect: 'noredirect',
    name: 'system',
    meta: { title: '权限管理', icon: 'lock', perms: 'sys:perms:index' },
    children: [
      {
        path: 'role',
        name: 'role',
        component: () => import('@/views/perms/role/index'),
        meta: { title: '角色管理', icon: 'role', perms: 'sys:perms:role' },
      },
      {
        path: 'employee',
        name: 'employee',
        component: () => import('@/views/perms/employee/index'),
        meta: { title: '成员管理', icon: 'people', perms: 'sys:perms:employee' },
      },
      {
        path: 'appUser',
        name: 'appUser',
        component: () => import('@/views/perms/appUser/index'),
        meta: { title: '用户管理', icon: 'people', perms: 'sys:perms:user' },
      }
    ]
  },
  {
    path: '/car',
    component: Layout,
    redirect: 'noredirect',
    name: 'car',
    meta: { title: '车型管理', icon: 'server', perms: 'car:model:index' },
    children: [
      {
        path: 'country',
        name: 'country',
        component: () => import('@/views/car/country/index'),
        meta: { title: '国别管理', icon: 'dept', perms: 'car:country:index' },
      },
      // {
      //   path: 'color',
      //   name: 'color',
      //   component: () => import('@/views/car/color/index'),
      //   meta: { title: '颜色管理', icon: 'theme' },
      // },
      // {
      //   path: 'displacement',
      //   name: 'displacement',
      //   component: () => import('@/views/car/displacement/index'),
      //   meta: { title: '排量管理', icon: 'fwb' },
      // },
      {
        path: 'brand',
        name: 'brand',
        component: () => import('@/views/car/brand/index'),
        meta: { title: '品牌管理', icon: 'role', perms: 'car:brand:index' },
      },
      // {
      //   path: 'model',
      //   name: 'model',
      //   component: () => import('@/views/car/model/index'),
      //   meta: { title: '型号管理', icon: 'chain' },
      // },
      {
        path: 'series',
        name: 'series',
        component: () => import('@/views/car/series/index'),
        meta: { title: '系列管理', icon: 'chain', perms: 'car:series:index' },
      },
      {
        path: 'info',
        name: 'info',
        component: () => import('@/views/car/info/index'),
        meta: { title: '车型信息管理', icon: 'chain', perms: 'car:carInfo:index' },
      },
      {
        path: 'vip',
        name: 'vip',
        component: () => import('@/views/car/vip/index'),
        meta: { title: 'VIP车型管理', icon: 'chain', perms: 'car:vip:index' },
      }
    ]
  },
  {
    path: '/video',
    component: Layout,
    redirect: 'noredirect',
    name: 'video',
    meta: { title: '视频管理', icon: 'mnt', perms: 'car:video:index' },
    children:[
      {
        path: 'classify',
        name: 'classify',
        component: () => import('@/views/video/classify/index'),
        meta: { title: '分类管理', icon: 'deploy', perms: 'video:classify:index' },
      },
      {
        path: 'info',
        name: 'info',
        component: () => import('@/views/video/info/index'),
        meta: { title: '视频信息管理', icon: 'develop', perms: 'video:info:index' },
      },
    ]
  },
  {
    path: '/broadcast',
    component: Layout,
    redirect: 'noredirect',
    name: 'broadcast',
    meta: { title: '直播管理', icon: 'mnt', perms: 'car:room:index' },
    children:[
      {
        path: 'room',
        name: 'room',
        component: () => import('@/views/broadcast/room/index'),
        meta: { title: '直播间管理', icon: 'deploy', perms: 'room:info:index' },
      }
    ]
  },
  {
    path: '/money',
    component: Layout,
    redirect: 'noredirect',
    name: 'money',
    meta: { title: '意向金管理', icon: 'mnt', perms: 'car:pay:index' },
    children:[
      {
        path: 'set',
        name: 'set',
        component: () => import('@/views/money/set/index'),
        meta: { title: '意向金设置', icon: 'deploy', perms: 'pay:setting:index' },
      },
      {
        path: 'pay',
        name: 'pay',
        component: () => import('@/views/money/pay/index'),
        meta: { title: '支付记录', icon: 'develop', perms: 'pay:record:index' },
      },
    ]
  },
  {
    path: '/sys',
    component: Layout,
    redirect: 'noredirect',
    name: 'sys',
    meta: { title: '系统设置', icon: 'system', perms: 'sys:system:index' },
    children:[
      {
        path: 'banner',
        name: 'banner',
        component: () => import('@/views/system/banner/index'),
        meta: { title: '轮播图设置', icon: 'image', perms: 'sys:carousel:index' },
      },
      {
        path: 'tool',
        name: 'tool',
        component: () => import('@/views/system/tool/index'),
        meta: { title: '实用工具', icon: 'image', perms: 'sys:tool:index' },
      },
      {
        path: 'help',
        name: 'help',
        component: () => import('@/views/system/help/index'),
        meta: { title: '帮助中心', icon: 'image', perms: 'sys:help:index' },
      },
      {
        path: 'leaveMsg',
        name: 'leaveMsg',
        component: () => import('@/views/system/leaveMsg/index'),
        meta: { title: '留言管理', icon: 'image', perms: 'sys:feedback:index' },
      },
      {
        path: 'clock',
        name: 'clock',
        component: () => import('@/views/system/clock/index'),
        meta: { title: '打卡管理', icon: 'image', perms: 'sys:clock:index' },
      },
    ]
  },
  {
    path: '/dataCount',
    component: Layout,
    redirect: 'noredirect',
    name: 'dataCount',
    meta: { title: '数据统计', icon: 'system', perms: 'data:count:index' },
    children:[
      {
        path: 'user',
        name: 'user',
        component: () => import('@/views/dataCount/user/index'),
        meta: { title: '用户统计', icon: 'image', perms: 'data:user:index' },
      },
      {
        path: 'car',
        name: 'car',
        component: () => import('@/views/dataCount/car/index'),
        meta: { title: '车型统计', icon: 'image', perms: 'data:car:index' },
      },
    ]
  },
  {
    path: '/customerCount',
    component: Layout,
    redirect: 'noredirect',
    name: 'customerCount',
    meta: { title: '访客追踪', icon: 'system', perms: 'data:count:index' },
    children:[
      {
        path: 'customer',
        name: 'customer',
        component: () => import('@/views/customerCount/customer/index'),
        meta: { title: '访客列表', icon: 'image', perms: 'data:user:index' },
      },
      {
        path: 'countInfo',
        name: 'countInfo',
        component: () => import('@/views/customerCount/countInfo/index'),
        meta: { title: '访客统计', icon: 'image', perms: 'data:car:index' },
      },
      {
        path: 'countCustomerCar',
        name: 'countCustomerCar',
        component: () => import('@/views/customerCount/countCustomerCar/index'),
        meta: { title: '车型访问统计', icon: 'image', perms: 'data:car:index' },
      },
    ]
  },
  {
    path: '/company',
    component: Layout,
    redirect: 'noredirect',
    name: 'sys',
    meta: { title: '企业信息管理', icon: 'dept', perms: 'sys:enterprise:index' },
    children:[
      {
        path: 'news',
        name: 'news',
        component: () => import('@/views/company/news/index'),
        meta: { title: '新闻管理', icon: 'doc', perms: 'enterprise:news:index' },
      },
      {
        path: 'info',
        name: 'info',
        component: () => import('@/views/company/info/index'),
        meta: { title: '公司管理', icon: 'image', perms: 'enterprise:company:index' },
      },
      {
        path: 'honor',
        name: 'honor',
        component: () => import('@/views/company/honor/index'),
        meta: { title: '荣誉资质管理', icon: 'image', perms: 'enterprise:honor:index' },
      },
      {
        path: 'team',
        name: 'team',
        component: () => import('@/views/company/team/index'),
        meta: { title: '团队风采管理', icon: 'image', perms: 'enterprise:team:index' },
      },
      {
        path: 'recruit',
        name: 'recruit',
        component: () => import('@/views/company/recruit/index'),
        meta: { title: '招聘管理', icon: 'image',  perms: 'enterprise:recruit:index' },
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('@/views/company/contact/index'),
        meta: { title: '联系人管理', icon: 'image', perms: 'enterprise:contact:index' },
      }
    ]
  },

  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  // base: '/admin',
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
// const originalPush = Router.prototype.push
// Router.prototype.push = function push (location) {
//   return originalPush.call(this, location).catch(err => err)
// }

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

/**
 * 路由拦截过滤处理器
 */
NProgress.configure({ showSpinner: false })
// 处理登录成功后，访问登录页，自动跳转首页
router.beforeEach((to, from, next) => {
  NProgress.start()
  if (getToken()) {
    /* has token */
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      // 当用户信息为空时，重新拉取用户信息 Object.keys 判断是否为空对象
      if (Object.keys(store.getters.user).length == 0) {
        store.dispatch('GetInfo').then(res => {
          if (res.code === 200) {
            next()
          } else {
            store.dispatch('FedLogOut').then(() => {
              Message.error(res.msg)
              next({ path: '/' })
            })
          }
        }).catch(err => {
          store.dispatch('FedLogOut').then(() => {
            Message.error(err)
            next({ path: '/' })
          })
        })
      } else {
        next()
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
