import axios from 'axios'
import { getToken, getLoginTime, getExpiresIn, getRefreshToken } from '@/assets/utils/auth.js'
import store from '@/store'
import { Message } from 'element-ui'

var loadingRefreshToken = false
// 创建axios实例
const service = axios.create({
  // baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/', // api 的 base_url
  baseURL: '/api',
  timeout: 1000 * 30, // 请求超时时间
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
})

// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {
    // 判断token是否过期  登录时间(毫秒) 过期时间(秒)
    if (new Date().getTime() - getLoginTime() > getExpiresIn() * 1000) {
      config.headers.token = getRefreshToken()
    } else {
      config.headers.token = getToken() // 让每个请求携带自定义token 请根据实际
    }
  }
  return config
}, error => {
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200
  // 获取错误信息
  const msg = res.data.msg
  if (code === 401) {
    Message.error('授权过期，请重新登录')
    // 3秒后，自动跳转到登录页面
    setTimeout(() => {
      store.dispatch('LogOut').then(() => {
        location.reload() // 为了重新实例化vue-router对象 避免bug
      })
    }, 1500)
  } else if (code === 500) {
    Message({
      message: msg,
      type: 'error'
    })
    return Promise.reject(new Error(msg))
  } else if (code !== 200) {
    Message({
      message: msg,
      type: 'error'
    })
    return Promise.reject('error')
  } else {
    //  token即将过期，需要刷新token
    if (res.headers.isrefresh === 'true' && !loadingRefreshToken) {
      loadingRefreshToken = true
      // 刷新token，获取新的token
      store.dispatch('RefreshToken').then(() => {
        loadingRefreshToken = false
      })
    }
    return res.data
  }
}, error => {
  let { message } = error
  if (message === 'Network Error') {
    message = '后端接口连接异常'
  } else if (message.includes('timeout')) {
    message = '系统接口请求超时'
  } else if (message.includes('Request failed with status code')) {
    message = '系统接口' + message.substr(message.length - 3) + '异常'
  }
  Message({
    message: message,
    type: 'error',
    duration: 5 * 1000
  })
  return Promise.reject(error)
})
export default service
