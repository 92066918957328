import Cookies from 'js-cookie'
import Config from '@/settings'

const TokenKey = 'Seller-Admin-Token'
const RefreshTokenKey = 'Seller-Admin-Refresh-Token'
const ExpiresInKey = 'Seller-Admin-Expires-In'
const LoginTimeKey = 'Seller-Admin-Login-time'

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  if (Cookies.get('rememberMe')) {
    return Cookies.set(TokenKey, token, { expires: Config.tokenCookieExpires })
  } else {
    return Cookies.set(TokenKey, token)
  }
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function getExpiresIn () {
  return Cookies.get(ExpiresInKey) || -1
}

export function setExpiresIn (time) {
  if (Cookies.get('rememberMe')) {
    return Cookies.set(ExpiresInKey, time, { expires: Config.tokenCookieExpires })
  } else {
    return Cookies.set(ExpiresInKey, time)
  }
}

export function removeExpiresIn () {
  return Cookies.remove(ExpiresInKey)
}

export function getRefreshToken () {
  return Cookies.get(RefreshTokenKey)
}

export function setRefreshToken (token) {
  if (Cookies.get('rememberMe')) {
    return Cookies.set(RefreshTokenKey, token, { expires: Config.tokenCookieExpires })
  } else {
    return Cookies.set(RefreshTokenKey, token)
  }
}

export function removeRefreshToken () {
  return Cookies.remove(RefreshTokenKey)
}

export function getLoginTime () {
  return Cookies.get(LoginTimeKey)
}

export function setLoginTime (loginTime) {
  if (Cookies.get('rememberMe')) {
    return Cookies.set(LoginTimeKey, loginTime, { expires: Config.tokenCookieExpires })
  } else {
    return Cookies.set(LoginTimeKey, loginTime)
  }
}

export function removeLoginTime () {
  return Cookies.remove(LoginTimeKey)
}
