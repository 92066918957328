import { login, logout, getInfo, refreshToken } from '@/api/login'
import {
  getToken, setToken, setExpiresIn, removeToken, setRefreshToken,
  getRefreshToken, removeRefreshToken, setLoginTime, removeLoginTime
} from '@/assets/utils/auth'

const user = {
  state: {
    token: getToken(),
    refreshToken: getRefreshToken(),
    user: {},
    roles: [],
    permissions: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_REFRESH_TOKEN: (state, refreshToken) => {
      state.refreshToken = refreshToken
    },
    SET_EXPIRES_IN: (state, time) => {
      state.expires_in = time
    },
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(res => {
          setToken(res.data.access_token)
          commit('SET_TOKEN', res.data.access_token)
          setRefreshToken(res.data.refresh_token)
          commit('SET_REFRESH_TOKEN', res.data.refresh_token)
          setExpiresIn(res.data.expires_in)
          commit('SET_EXPIRES_IN', res.data.expires_in)
          setLoginTime(new Date().getTime())
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          if (res.data.roles && res.data.roles.length > 0) {
            commit('SET_ROLES', res.data.roles)
            commit('SET_PERMISSIONS', res.data.permissions)
          } else {
            commit('SET_ROLES', ['ROLE_DEFAULT'])
          }
          commit('SET_USER', res.data.user)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 刷新token
    RefreshToken ({ commit, state }) {
      return new Promise((resolve, reject) => {
        refreshToken(state.refreshToken).then((res) => {
          setToken(res.data.access_token)
          commit('SET_TOKEN', res.data.access_token)
          setExpiresIn(res.data.expires_in)
          commit('SET_EXPIRES_IN', res.data.expires_in)
          setLoginTime(new Date().getTime())
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 退出系统 清除所有cookie缓存
    LogOut ({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token, state.refreshToken).then((res) => {
          commit('SET_TOKEN', '')
          commit('SET_REFRESH_TOKEN', '')
          commit('SET_USER', {})
          commit('SET_ROLES', [])
          commit('SET_PERMISSIONS', [])
          removeToken()
          removeRefreshToken()
          removeLoginTime()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出 清除所有cookie缓存
    FedLogOut ({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_REFRESH_TOKEN', '')
        commit('SET_USER', {})
        commit('SET_ROLES', [])
        commit('SET_PERMISSIONS', [])
        removeToken()
        removeRefreshToken()
        removeLoginTime()
        resolve()
      })
    }
  }
}

export default user
